.hero{
    height:90vh;
    width:100%;
    background-color:#000000;
}

.section1{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    position:relative;
    height:100vh;
    width:100%;
    background-color:#000;
    overflow:visible;
}

.bg-lettering-container{
    display:flex;
    flex-direction:column;
    position:absolute;
    top:0;
    height:90vh;
    width:100%;
    z-index:10;
    background-color:#000;
}

.bg-lettering-row{
    height:45vh;
    width:100%;
    overflow:hidden;
}

.bg-letters{
    width:100%;
    font-family: 'GTAmericabold', sans-serif;
    font-size:40vh;
    color:#23232396;
    font-weight:900;
    letter-spacing: none;
}

.title-box{
    display:flex;
    flex-direction:row;
    width:100%;
    height:33%;
    z-index:20;
    box-sizing:border-box;
    padding-left:40px;
    padding-right:40px;
}

.title-box-img-section{
    width:30%;
    height:100%;
    position:relative;
}

.title-box-text-section{
    width:70%;
    height:100%;
    overflow:hidden;
}

.title-box-img{
    position:absolute;
    bottom:-5%;
    right:0%;
    width:40%;
    height:auto;
}


.title-line-1{
    width:100%;
    font-family: "GTAmerica", sans-serif;
    font-size:50px;
    font-weight:100;
    color:rgb(255, 255, 255);
}

.title-line-2{
    width:100%;
    font-family: "Monumentextended", sans-serif;
    font-size:168px;
    font-weight:900;
    color:#fff;
    line-height:160px;
}

.underline-segment{
    height:70px;
    width:350px;
    background:linear-gradient(to right, rgb(92, 12, 112), #cb6ee3, #6AC5F7, rgb(24, 21, 240));
    content:"";
    z-index:30;
    float:left;
    margin-top:-10px;
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media only screen and (min-width:320px){

    .bg-letters{
        width:100%;
        font-family: "GTAmericabold", sans-serif;
        font-size:20vh;
        color:#151515c2;
    }

    .bg-lettering-row{
        height:25vh;
        width:100%;
        overflow:hidden;
    }
    
    .title-box{
        flex-direction:column;
        width:100%;
        height:80%;
        padding-left:20px;
        padding-right:20px;
    }

    .title-box-img-section{
        width:100%;
        height:60%;
        position:relative;
    }
    
    .title-box-text-section{
        width:100%;
        height:40%;
        overflow:hidden;
    }

    .title-line-2{
        font-size:55px;
        line-height:75px;
    }

    .underline-segment{
        height:15px;
        width:150px;
    }

    .title-box-img{
        bottom:-16%;
        width:40%;
    }
}

@media only screen and (min-width:375px){

    .bg-letters{
        width:100%;
        font-family: "GTAmericabold", sans-serif;
        font-size:20vh;
        color:#151515c2;
    }

    .bg-lettering-row{
        height:25vh;
        width:100%;
        overflow:hidden;
    }
    
    .title-box{
        flex-direction:column;
        width:100%;
        height:80%;
        padding-left:20px;
        padding-right:20px;
    }

    .title-box-img-section{
        width:100%;
        height:60%;
        position:relative;
    }
    
    .title-box-text-section{
        width:100%;
        height:40%;
        overflow:hidden;
    }

    .title-line-2{
        font-size:70px;
        line-height:75px;
    }

    .underline-segment{
        height:15px;
        width:150px;
    }

    .title-box-img{
        bottom:-16%;
        width:40%;
    }
}

@media only screen and (min-width:768px){

    .title-box{
        height:33%;
        flex-direction:row;
        width:100%;
        height:33%;
        padding-left:40px;
        padding-right:40px;
    }

    .title-box-img-section{
        width:30%;
        height:100%;
        position:relative;
    }
    
    .title-box-text-section{
        width:70%;
        height:100%;
        overflow:hidden;
    }

    .bg-letters{
        width:100%;
        font-family: "GTAmericabold", sans-serif;
        font-size:40vh;
        color:#15151579;
    }

    .bg-lettering-row{
        height:45vh;
        width:100%;
        overflow:hidden;
    }

    .title-line-2{
        font-size:95px;
        line-height:90px;
    }
    .underline-segment{
        height:20px;
        width:300px;
    }

    .title-box-img{
        bottom:20%;
        width:60%;
    }
}

@media only screen and (min-width:1024px){

    .title-box{
        height:33%;
    }

    .title-line-2{
        font-size:140px;
        line-height:130px;
    }

    .underline-segment{
        height:30px;
        width:350px;
    }

    .title-box-img{
        bottom:-5%;
        width:50%;
    }
}

@media only screen and (min-width:1200px){

    .title-box{
        height:33%;
    }

    .title-line-2{
        font-size:160px;
        line-height:160px;
    }

    .title-box-img{
        width:40%;
    }
}


@media only screen and (min-width:1440px){

    .title-box{
        height:35%;
    }

    .title-line-2{
        font-size:190px;
        line-height:160px;
    }
}
