.section-4{    
    display:flex;
    flex-direction:row;
    height:100vh;
    width:auto;
    background-color:#000;
    overflow:hidden;
}

.section-4-left{
    display:grid;
    place-items:center;
    height:100%;
    width:50%;
    position:relative;
    top:1vh;
    
}

.section-4-right{
    display:grid;
    place-items:center;
    height:100%;
    width:50%;
    position:relative;
    top:1vh;
}

.section-4-image-container{
    display:grid;
    place-items:center;
    height:100%;
    width:100%;
   
}

.blog-image{
    width:300px;
    display:block;
    z-index:4;
    border-radius: 20px;
}

.blog-content-container{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    height:auto;
    width:80%;
}

.blog-heading{
    display:block;
    font-family:"Monumentextended", sans-serif;
    font-size:50px;
    font-weight:700;
    color:#fff;
    letter-spacing:1px;
    text-align:left;
}

.blog-content{
    display:block;
    font-family:"Roboto", sans-serif;
    font-size:25px;
    font-weight:500;
    color:#fff;
    letter-spacing:1px;
    line-height:30px;
}

.resume-button{
    background-color:#3249C1;
    height:50px;
    width:150px;
    border-radius:8px;
    color:#fff;
    font-family:"Roboto", sans-serif;
    font-size:20px;
    font-weight:700;
    border:none;
    outline:none;
    text-decoration:none;
    margin-top:20px;
    display:grid;
    place-items: center;
}

.box{
    display:grid;
    place-items:center;
    width: 300px;
    height: auto;
    background-color: white;
    position: relative;
}


@media only screen and (min-width:320px){

    .section-4{    
        display:flex;
        flex-direction:column-reverse;
        height:100vh;
        width:100%;
    }

    .section-4-left{
        height:50%;
        width:100%;
    }

    .section-4-right{
        height:50%;
        width:100%;
    }

    .blog-heading{
        font-size:30px;
        text-align:center;
    }

    .blog-content{
        font-size:16px;
        text-align:center;
    }

    .blog-content-container{
        align-items:center;
        justify-content:center;
    }

    .resume-button{
        background-color:#3249C1;
        height:30px;
        width:60px;
        border-radius:8px;
        color:#fff;
        font-family:"Roboto", sans-serif;
        font-size:10px;
        font-weight:700;
    }

    .blog-image{
        width:280px;
    }

    .box{
        width: 200px;
    }

    
}

@media only screen and (min-width:375px){
    .section-4{    
        display:flex;
        flex-direction:column-reverse;
        height:100vh;
        width:100%;
    }

    .section-4-left{
        height:50%;
        width:100%;
    }

    .section-4-right{
        height:50%;
        width:100%;
    }

    .blog-heading{
        font-size:30px;
        text-align:center;
    }

    .blog-content{
        font-size:16px;
        text-align:center;
    }

    .blog-content-container{
        align-items:center;
        justify-content:center;
    }

    .resume-button{
        height:40px;
        width:80px;
        border-radius:8px;
        color:#fff;
        font-size:14px;
    }

    .blog-image{
        width:350px;
    }

    .box{
        width: 250px;
    }
}

@media only screen and (min-width:768px){
    .section-4{    
        display:flex;
        flex-direction:row;
        height:100vh;
        width:100%;
    }

    .section-4-left{
        height:100%;
        width:50%;
    }

    .section-4-right{
        height:100%;
        width:50%;
    }

    .blog-heading{
        font-size:35px;
        text-align:left;
    }

    .blog-content{
        font-size:18px;
        text-align:left;
    }

    .blog-content-container{
        align-items:flex-start;
        justify-content:center;
    }

    .resume-button{
        height:50px;
        width:150px;
        border-radius:8px;
        color:#fff;
        font-size:18px;
    }

    .blog-image{
        width:550px;
    }

    .box{
        width: 250px;
    }
}

@media only screen and (min-width:1024px){
    .blog-heading{
        font-size:50px;
    }

    .blog-content{
        font-size:25px;
    }

    .resume-button{
        height:50px;
        width:150px;
        border-radius:8px;
        color:#fff;
        font-size:20px;
    }

    .blog-image{
        width:400px;
    }

    .box{
        width: 300px;
    }
}

@media only screen and (min-width:1200px){
    .blog-image{
        width:430px;
    }

    .box{
        width: 330px;
    }
}

@media only screen and (min-width:1440px){
    .blog-image{
        width:480px;
    }

    .box{
        width: 380px;
    }
}