.sidebarMainContainer{
    width:20vw;
    height:100%;
    background-color:#352f36;
    border-radius:10px;
    box-sizing: border-box;
    padding:20px;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.imgContainer{
    width:100%;
    height:50%;
    background-color:white;
    border-radius:10px;
}