.footer {
    width: 100%;
    height: auto;
    background-color: #212326;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 100px 40px 100px;
    box-sizing: border-box
}

.main-footer-container {
    display: flex;
    width:100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer-right {
    height: auto;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-logo {
    height: 100px;
    width: auto;
}

.footer-text-main{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #FFFFFF;
}

.footer-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.footer-heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #FFFFFF;
}

.footer-app-store-qr {
    height: 100px;
    width: 100px;
}

.footer-app-store-link-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-app-store-img {
    height: 38px;
    width: auto;
    margin:5px;
}

.sub-footer-container {
    width: 100%;
    height: auto;
}

.sub-footer-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9AA0A6;
    margin:0px 5px;
}

.sub-footer-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9AA0A6;
}

@media only screen and (min-width:320px) {
    .footer {
        padding: 60px 20px 40px 20px;
    }

    .main-footer-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-left {
        width: 100%;
        margin-bottom:20px;
    }

    .footer-right {
        height: auto;
        width: 100%;
        margin-bottom:40px;
    }

    .footer-logo {
        height: 150px;
    }

    .footer-text {
        font-size: 12px;
        line-height: 20px;
    }

    .footer-heading {
        font-size: 14px;
        line-height: 16px;
    }

    .footer-app-store-qr {
        height: 100px;
        width: 100px;
    }

    .footer-app-store-img {
        height: 30px;
        width: auto;
    }

    .sub-footer-container {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sub-footer-text {
        font-size: 12px;
        line-height: 15px;
        text-align:center;
    }

}

@media only screen and (min-width:375px) {
    .footer {
        padding: 60px 30px 40px 30px;
    }

    .main-footer-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-left {
        width: 100%;
        margin-bottom:20px;
    }

    .footer-right {
        height: auto;
        width: 100%;
        margin-bottom:40px;
    }

    .footer-logo {
        height: 150px;
    }

    .footer-text {
        font-size: 12px;
        line-height: 20px;
    }

    .footer-heading {
        font-size: 16px;
        line-height: 17px;
    }

    .footer-app-store-qr {
        height: 100px;
        width: 100px;
    }

    .footer-app-store-img {
        height: 30px;
        width: auto;
    }

    .sub-footer-container {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sub-footer-text {
        font-size: 14px;
        line-height: 17px;
        text-align:center;
    }

}

@media only screen and (min-width:425px) {
    .footer {
        padding: 60px 30px 40px 30px;
    }

    .main-footer-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-left {
        width: 100%;
        margin-bottom:20px;
    }

    .footer-right {
        height: auto;
        width: 100%;
        margin-bottom:40px;
    }

    .footer-logo {
        height: 150px;
    }

    .footer-text {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-heading {
        font-size: 16px;
        line-height: 17px;
    }

    .footer-app-store-qr {
        height: 100px;
        width: 100px;
    }

    .footer-app-store-img {
        height: 38px;
        width: auto;
    }

    .sub-footer-container {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sub-footer-text {
        font-size: 14px;
        line-height: 17px;
        text-align:center;
    }

}

@media only screen and (min-width:768px){
    .main-footer-container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .footer-left {
        width: 45%;
        margin-bottom:20px;
    }

    .footer-right {
        height: auto;
        width: 45%;
        margin-bottom:40px;
    }

}

@media only screen and (min-width:1024px) {
    .footer {
        padding: 40px 60px 40px 60px;
    }

    .main-footer-container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .footer-left {
        height: auto;
        width: 45%;
        margin-bottom:0px;
    }

    .footer-right {
        height: auto;
        width: 45%;
        margin-bottom:0px;
    }

    .footer-logo {
        height: 150px;
    }

    .footer-text {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-heading {
        font-size: 16px;
        line-height: 17px;
    }

    .footer-app-store-qr {
        height: 100px;
        width: 100px;
    }

    .footer-app-store-img {
        height: 38px;
        width: auto;
    }

    .sub-footer-container {
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .sub-footer-text {
        font-size: 14px;
        line-height: 17px;
        text-align:left;
    }

}

@media only screen and (min-width:1200px){
    .footer {
        padding: 40px 100px 40px 100px;
    }

    .footer-left {
        height: auto;
        width: 30%;
        margin-bottom:0px;
    }

    .footer-right {
        height: auto;
        width: 30%;
        margin-bottom:0px;
    }
}