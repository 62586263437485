.product-title{
    color:white;
    font-family: 'Monumentextended',sans-serif;
}

.product-desc{
    color:white;
    font-family: 'Roboto',sans-serif;
    font-size:20px;
    margin-right:30px;
    margin-top:30px;
}