/* Cursor.css */
.custom-cursor {
    position: fixed;
    width: 25px; /* Adjust the size of the cursor */
    height: 25px; /* Adjust the size of the cursor */
    border: 3px solid #fff; /* Cursor outline color */
    border-radius: 50%; /* Make the cursor circular */
    background-color: transparent; /* Make the cursor transparent */
    pointer-events: none; /* Ensure the cursor doesn't interfere with interactions */
    transform: translate(-50%, -50%); /* Center the cursor on the mouse pointer */
    mix-blend-mode: difference; /* Optional: Enhance visibility by using blend mode */
    z-index: 9999; /* Ensure the cursor appears above other elements */
  }

@media only screen and (min-width:320px){
  .custom-cursor {
    display:none;
  }
}

@media only screen and (min-width:900px){
  .custom-cursor {
    display:block;
  }
}