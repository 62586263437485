/* Navbar.css */
.header {
    background-color: black;
    display: flex;
    box-sizing:border-box;
    padding:10px 50px 10px 50px;
    justify-content: space-between;
    align-items: center;
    height: 70px;
  }
  
.nav-left-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    height:100%;
    width:5%;
}

.nav-right-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    box-sizing:border-box;
    justify-content:space-between;
    height:100%;
    width:auto;
}

.nav-cta{
    font-family: 'Montserrat', sans-serif;
    font-weight:700;
    font-size:12px;
    border-radius:100px;
    box-sizing:border-box;
    border:1px solid #f4f2f2;
    background-color:#000000;
    color:#ffffff;
    padding:10px 20px 10px 20px;
    margin-right:20px;
}

.nav-icon{
    height:30px;
    width:30px;
}

.nav-section{
    display:none;
    flex-direction:row;
    align-items: center;
    justify-content:center;
    position:absolute;
    top:0;
    box-sizing:border-box;
    background:#000000;
    height:100vh;
    width:100%;
    z-index: 30;
}

.nav-section-left{
    display:flex;
    width:60%;
    height:100%;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-color:#f4f2f2;
}

.nav-section-right{
    display:flex;
    width:40%;
    height:100%;
    background-color:#000000;
}

.nav-section-left-row{
    width:80%;
    height:100px;
}

.nav-item-text{
    font-size:90px;
    font-family: 'Monumentextended',sans-serif;
    font-weight:700;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #191919;
    color:#fff;
}

.nav-item-bullet{
    font-size:30px;
    position:relative;
    font-family: 'Bebas Neue',sans-serif;
    font-weight:400;
    transform: rotate(90deg);
}

.nav-right-top-container{
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items:center;
    position:relative;
    top:0;
    width:100%;
    height:70px;
    box-sizing:border-box;
    padding:10px 50px 10px 50px;
}

@media only screen and (min-width:320px){
    .header {
        padding:10px 10px 10px 10px;
    }

    .nav-cta{
        margin-right:0px;
    }

    .nav-icon{
        display:none;
    }

}

@media only screen and (min-width:760px){
    .header {
        padding:10px 50px 10px 50px;
    }
    .nav-cta{
        margin-right:20px;
    }
    .nav-icon{
        display:block;
    }
}