.section-3{    
    display:flex;
    flex-direction:row;
    height:100vh;
    width:auto;
    background-color:#000;
    overflow:hidden;
}

.section-3-left{
    display:grid;
    place-items:center;
    height:100%;
    width:50%;
    position:relative;
    top:1vh;
    
}

.section-3-right{
    display:grid;
    place-items:center;
    height:100%;
    width:50%;
    position:relative;
    top:1vh;
}

.section-3-image-container{
    display:grid;
    place-items:center;
    height:100%;
    width:100%;
   
}

.about-image{
    width:300px;
    display:block;
    z-index:4;
    border-radius: 20px;
}

.about-content-container{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    height:auto;
    width:80%;
}

.about-heading{
    display:block;
    font-family:"Monumentextended", sans-serif;
    font-size:50px;
    font-weight:700;
    color:#fff;
    letter-spacing:1px;
    text-align:left;
}

.about-content{
    display:block;
    font-family:"Roboto", sans-serif;
    font-size:25px;
    font-weight:500;
    color:#fff;
    letter-spacing:1px;
    line-height:30px;
}

.resume-button{
    background-color:#3249C1;
    height:50px;
    width:150px;
    border-radius:8px;
    color:#fff;
    font-family:"Roboto", sans-serif;
    font-size:20px;
    font-weight:700;
    border:none;
    outline:none;
    text-decoration:none;
    margin-top:20px;
    display:grid;
    place-items: center;
}

.box{
    display:grid;
    place-items:center;
    width: 300px;
    height: auto;
    background-color: white;
    position: relative;
    border-radius: 20px;
}
.box::before,
.box::after{
    content: "";
    position: absolute;
    background: linear-gradient(45deg,#ff0000,#00f0f0,#00ff00,#0000ff,#ff0000,#00f0f0,#00ff00,#0000ff,#f00f0f);
    width: 100%;
    height: 100%;
    transform: scale(1.02);
    z-index: 1;
    background-size: 500%;
    animation: animation 20s linear infinite;
    border-radius: 20px;
}
.box::after{
    filter: blur(50px);
}

@keyframes animation{
    0%{
        background-position: 0 0;
    }
    50%{
        background-position: 300% 0;
    }
    100%{
        background-position: 0 0;
    }
}

@media only screen and (min-width:320px){

    .section-3{    
        display:flex;
        flex-direction:column;
        height:100vh;
        width:100%;
    }

    .section-3-left{
        height:50%;
        width:100%;
    }

    .section-3-right{
        height:50%;
        width:100%;
    }

    .about-heading{
        font-size:30px;
        text-align:center;
    }

    .about-content{
        font-size:16px;
        text-align:center;
    }

    .about-content-container{
        align-items:center;
        justify-content:center;
    }

    .resume-button{
        background-color:#3249C1;
        height:30px;
        width:60px;
        border-radius:8px;
        color:#fff;
        font-family:"Roboto", sans-serif;
        font-size:10px;
        font-weight:700;
    }

    .about-image{
        width:200px;
    }

    .box{
        width: 200px;
    }

    
}

@media only screen and (min-width:375px){
    .section-3{    
        display:flex;
        flex-direction:column;
        height:100vh;
        width:100%;
    }

    .section-3-left{
        height:50%;
        width:100%;
    }

    .section-3-right{
        height:50%;
        width:100%;
    }

    .about-heading{
        font-size:30px;
        text-align:center;
    }

    .about-content{
        font-size:16px;
        text-align:center;
    }

    .about-content-container{
        align-items:center;
        justify-content:center;
    }

    .resume-button{
        height:40px;
        width:80px;
        border-radius:8px;
        color:#fff;
        font-size:14px;
    }

    .about-image{
        width:250px;
    }

    .box{
        width: 250px;
    }
}

@media only screen and (min-width:768px){
    .section-3{    
        display:flex;
        flex-direction:row;
        height:100vh;
        width:100%;
    }

    .section-3-left{
        height:100%;
        width:50%;
    }

    .section-3-right{
        height:100%;
        width:50%;
    }

    .about-heading{
        font-size:35px;
        text-align:left;
    }

    .about-content{
        font-size:18px;
        text-align:left;
    }

    .about-content-container{
        align-items:flex-start;
        justify-content:center;
    }

    .resume-button{
        height:50px;
        width:150px;
        border-radius:8px;
        color:#fff;
        font-size:18px;
    }

    .about-image{
        width:250px;
    }

    .box{
        width: 250px;
    }
}

@media only screen and (min-width:1024px){
    .about-heading{
        font-size:50px;
    }

    .about-content{
        font-size:25px;
    }

    .resume-button{
        height:50px;
        width:150px;
        border-radius:8px;
        color:#fff;
        font-size:20px;
    }

    .about-image{
        width:300px;
    }

    .box{
        width: 300px;
    }
}

@media only screen and (min-width:1200px){
    .about-image{
        width:330px;
    }

    .box{
        width: 330px;
    }
}

@media only screen and (min-width:1440px){
    .about-image{
        width:380px;
    }

    .box{
        width: 380px;
    }
}