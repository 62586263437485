.mainContainer{
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  box-sizing:border-box;
  height:90vh;
  width:100%;
  background-color: #000000;
  padding:30px;
}